import { store } from "react-notifications-component";
const showNotification = (title, message, type, persist = false) => {
  let dismiss = {
    pauseOnHover: true,
    showIcon: true,
    click: true,
  };
  if (type === "danger" || persist === true) {
    dismiss.duration = 0;
  } else {
    dismiss.duration = 3000;
  }
  store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: dismiss,
  });
};
export { showNotification as default };
