import React, {FunctionComponent} from 'react';
import {Route, Redirect} from "react-router-dom";
import UploadSessionModel from "../../UploadSessionModel";

interface PrivateRouteProps {
  uploadSession: UploadSessionModel;
  onFailRedirectTo: string;
  path: string;
}

/**
 * This is a wrapper for routes that are dependant on a valid uploadSession
 * being in place.
 * If uploadSession.isAuthenticated is false, the component will redirect to "/".
 * Otherwise, the child components are displayed.
 */
const PrivateRoute:FunctionComponent<PrivateRouteProps> = ({
    uploadSession,
    onFailRedirectTo,
    children,
    ...rest}) => {

  return (
    <Route
      {...rest}
      render={({ location }) =>
        uploadSession.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: onFailRedirectTo,
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute;
