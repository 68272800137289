import UploadInvitationModel from "../../UploadInvitationModel";
import {escapeUnicode} from "../../../helpers/strEscape";
var https = require('https');

/**
 * Message used for providing user-submitted meta data to the API
 */
export default class AssetUserMetaDataModel {
  uploadSessionToken: string;
  assetFileName: string;
  meta: { [key:string]:any; }

  public constructor({
      uploadSessionToken,
      assetFileName,
      meta}) {
    this.uploadSessionToken = uploadSessionToken;
    this.assetFileName = assetFileName;
    this.meta = meta;
  }

  /**
   * Send the upload event notification. The response is ignored.
   * An UploadInvitationModel instance is required for authentication,
   * if this is null, the message will not be sent.
   */
  public send(invitation: UploadInvitationModel | null): void {
    if(invitation == null ){
      return;
    }
    const payload = JSON.stringify(this);
    const payloadEsc = escapeUnicode(payload);
    const opts = {
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': payloadEsc.length
      },
      body: payloadEsc
    }
    const signedRequest = invitation.signRequest(opts, "/addAssetMeta/", "POST");

    function request(opts) {
      try {
        https.request(opts, function(res) {
            //res.pipe(process.stdout)
          }).end(opts.body || '')
      } catch (e) {
        console.error(e);
      }
    }
    request(signedRequest);
  }
}
