import UploadSessionModel from "../UploadSessionModel";
import UploadAssetModel from "./models/UploadAssetModel";
import UploadEventModel from "./models/UploadEventModel";
import AssetUserMetaDataModel from "./models/AssetUserMetaDataModel";
import UploadInvitationModel from "../UploadInvitationModel";
import { UploadProgressProps } from "./views/AssetUploadProgressView";

const AWS = require('aws-sdk');

export interface UploadProgress {
  percent: number;
  complete?: boolean;
  error?: any;
  retries?: number;
}

/**
 * Manages upload to AWS S3 for a single asset
 */
export default class S3UploadSession {
  private asset: UploadAssetModel;
  private session: UploadSessionModel;
  private onProgressUpdate: (prog: UploadProgressProps) => void;
  private resetUploaderState: () => void;

  /**
   * @param {UploadAssetModel} asset - the file and associated metadata, set by
   * the user.
   * @param {UploadSession} session - temporary IAM session credentials provided
   * to the user upon claiming a valid UploadInvitation.
   * @param {UploadProgressProps} onProgressUpdate - callback to fire to
   * inform dependants of the updated status/progress
   */
  public constructor(
    asset: UploadAssetModel,
    session: UploadSessionModel,
    onProgressUpdate: (prog: UploadProgressProps) => void,
    resetUploaderState: () => void
  ) {

    this.asset = asset;
    this.session = session;
    this.onProgressUpdate = onProgressUpdate;
    this.resetUploaderState = resetUploaderState
  }

  private initAWS(invitation: UploadInvitationModel) {
    AWS.config.update({
      region: invitation.region,
      credentials: {
        accessKeyId: invitation.accessKeyId,
        secretAccessKey: invitation.secretAccessKey,
        sessionToken: invitation.sessionToken
      },
      useAccelerateEndpoint: invitation
          .clientConfig.useTransferAcceleration,
      httpOptions: {
        connectTimeout: 50000,
        timeout: 300000
      },
      maxRetries: 3,

    });
  }

  /**
   * start the upload process
   */
  public start = (): void => {
    // Create an object of formData to pass to the file reader
    const formData = new FormData();
    formData.append(
      "the file",
      this.asset.file,
      this.asset.file.name
    );

    const invitation = this.session.invitation as UploadInvitationModel;

    if (this.session.invitation == null) {
      console.error("appState.credentials is null");
      return;
    }

    this.initAWS(this.session.invitation);

    // Use S3 ManagedUpload class as it supports multipart uploads
    console.log("starting upload of", this.asset.file.name);
    const uploader = new AWS.S3.ManagedUpload({
      params: {
        Bucket: this.session.invitation.bucket,
        Key:
          this.session.token
          + "/" + this.asset.file.name,
        Body: this.asset.file
      },
      tags: [{
        Key: "uploadSessionToken",
        Value: this.session.token
      }],
      leavePartsOnError: true
    });

    uploader.on('httpUploadProgress', (evt) => {
      this.onProgressUpdate({
        percent: ((evt.loaded * 100) / evt.total)
      });
    });

    const promise = uploader.promise();

    new UploadEventModel({
      "messageType": "ASSET_UPLOAD_STARTING",
      "uploadSessionToken": this.session.token,
      "meta": {
        ...this.asset.meta,
        "assetName": this.asset.file.name,
        "assetSize": this.asset.file.size,

      }
    }).send(invitation);

    new AssetUserMetaDataModel({
      "uploadSessionToken": this.session.token,
      "assetFileName": this.asset.file.name,
      "meta": this.asset.meta
    }).send(invitation);

    promise.then((data) => {
      console.log("Successfully uploaded object.");
      this.onProgressUpdate({
        percent: 100,
        complete: true
      });
      new UploadEventModel({
        "messageType": "ASSET_UPLOAD_COMPLETED",
        "uploadSessionToken": this.session.token,
        "meta": {
          "assetName": this.asset.file.name,
          "assetSize": this.asset.file.size
        }
      }).send(invitation);
    }, (err) => {
      console.log(err)
      this.onProgressUpdate({
        percent: 0,
        error: err
      });
      this.resetUploaderState();
      new UploadEventModel({
        "messageType": "ASSET_UPLOAD_ERROR",
        "uploadSessionToken": this.session.token,
        "meta": {
          "assetName": this.asset.file.name,
          "assetSize": this.asset.file.size,
          "errorr": err
        }
      }).send(invitation);
    }).catch((err) => {
      console.error("There was an error uploading your object: ", err);
    });
  };
}
