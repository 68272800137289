import APIErrorResponseModel from "./APIErrorResponseModel";
// import crypto from "crypto-js";

const aws4 = require('aws4');

export class ClientConfiguration {
  expectedFileTypes: string[] = [];
  maxFileSizeBytes: number = 2684354560;
  showTags?: boolean;
  allowedTags?: string[];
  showNotes?: boolean;
  notesCharLimit?: number;
  showTurnaround?: boolean;
  turnaroundOptions?: string[];
  t1cCustomerKey?: string;
  t1cProjectKey?: string;
  t1cUserFullName?: string;
  t1cProjectName?: string;
  t1cCompanyName?: string;
  t1cDefaultTurnaround?: string;
  supporting_asset_tags?: string[] = [];
  primary_asset_tags?: string[] = [];
  useTransferAcceleration?: boolean = true;
  displayCaptureModal?: boolean = false;
}

/**
 * Successfull response to an inviation to upload, as provided by the API.
 * The response dictates the bucket/region to be used and provides temporary
 * IAM credentials
 */
export default class UploadInvitationModel {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
  bucket: string;
  region: string;
  clientInstruction: string;
  clientConfig: ClientConfiguration;

  /**
   * Claim a UploadInvitation, using the token and passcode provided to the client.
   */
  public static claim(token: string, passcode: string): Promise<UploadInvitationModel> {

    return new Promise((resolve, reject) => {
      fetch("https://" + process.env.REACT_APP_API_HOSTNAME + '/validateInvite/',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: token,
          passcode: passcode
        })
      }).then((response: Response) => {
        response.json().then((data: any) => {
          if (response.status === 200) {
            resolve(new UploadInvitationModel(data));
          } else {
            reject(new APIErrorResponseModel(data).error);
          }
        }).catch((reason: any) => {
          reject(reason);
        });
      }).catch((reason: any) => {
        reject(reason);
      });
    });
  }

  /**
   * Based upon the temporary credentials returned by the invication, sign an
   * HTTP request object using AWS Sig V4 so that the client can authenticate
   * with selected API endpoints via IAM authentication.
   */
  public signRequest(request: { [key:string]:any; }, path="/", method="GET")
    : { [key:string]:any; } {

    request.service = 'execute-api';
    request.host = process.env.REACT_APP_API_HOSTNAME;
    request.region = process.env.REACT_APP_AWS_REGION;
    request.path = path;
    request.method = method;
    const signedRequest = aws4.sign(request, {
      secretAccessKey: this.secretAccessKey,
      accessKeyId: this.accessKeyId,
      sessionToken: this.sessionToken
    });
    return signedRequest;
  }

  public constructor({
      secretAccessKey,
      sessionToken,
      accessKeyId,
      bucket,
      region,
      clientInstruction,
      clientConfig}) {
    this.secretAccessKey = secretAccessKey;
    this.sessionToken = sessionToken;
    this.accessKeyId = accessKeyId;
    this.bucket = bucket;
    this.region = region;
    this.clientInstruction = clientInstruction? clientInstruction : "";
    this.clientConfig = clientConfig? clientConfig : new ClientConfiguration();

    if (!clientConfig.useTransferAcceleration) {
      console.log("S3 Transfer Acceleration has been toggled OFF");
    }
  }
}
