import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import Header from "./Header";

const Layout = ({ body, loggedIn = null }) => {
  let appClass = "app-container";
  if (loggedIn) appClass = appClass + " logged-in";
  return (
    <div className={appClass}>
      {loggedIn && <Header loggedIn={loggedIn} />}

      <MDBContainer fluid className="page-content">
        <MDBRow>
          <MDBCol>{body}</MDBCol>
        </MDBRow>
        {/* <MDBRow>
          <MDBCol>
            <p className="text-right">&copy; Take 1 Script Services Ltd</p>
          </MDBCol>
        </MDBRow> */}
      </MDBContainer>
    </div>
  );
};

export default Layout;
