import { MDBChip } from "mdbreact";
import React, { FunctionComponent, useEffect } from "react";

import { AssetViewProps } from "./AssetView";

/**
 * Allows a user to enter multiple tags against a given asset.
 * This is stored as asset.meta.tags
 */
const AssetTags: FunctionComponent<AssetViewProps> = (props) => {
  useEffect(() => {
    props.asset.meta.tags = [];
    props.onModified(props.asset.meta);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectId = props.asset.file.name + "-tags-select";
  const handleChange = (newTag) => {
    props.asset.meta.tags.push(newTag);
    props.onModified(props.asset.meta);
  };
  let availableTags: string[] = [];
  if (props.clientConfig.primary_asset_tags) {
    if (props.asset.meta.tags) {
      availableTags = props.clientConfig.primary_asset_tags.filter((allTag) => !props.asset.meta.tags.includes(allTag));
    } else {
      availableTags = props.clientConfig.primary_asset_tags;
    }
  }

  const onRemoveTagClick = (e, tag) => {
    e.preventDefault();
    props.asset.meta.tags = props.asset.meta.tags.filter((oldTag) => oldTag !== tag);
    props.onModified(props.asset.meta);
  };

  return (
    <tr className="asset-tags-row">
      <td></td>
      <td>
        <div className="form-group">
          <select
            id={selectId}
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            value="0"
            disabled={props.isUploading || props.asset.uploadProgress?.complete}
          >
            <option value="0">Select tags</option>
            {availableTags.map((tag, i) => {
              return (
                <option key={i} value={tag}>
                  {tag}
                </option>
              );
            })}
          </select>
        </div>
      </td>
      <td colSpan={2}>
        {props.asset.meta.tags &&
          props.asset.meta.tags.map((tag) => {
            return (
              <MDBChip close={!props.isUploading && !props.asset.uploadProgress?.complete} handleClose={(e) => onRemoveTagClick(e, tag)}>
                {tag}
              </MDBChip>
            );
          })}
      </td>
      {/* <td></td> */}
    </tr>
  );
};

export default AssetTags;
