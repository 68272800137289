import { MDBBtn } from "mdbreact";
import React, { FunctionComponent } from "react";

import { AssetViewProps } from "./AssetView";

/**
 * Allows a user to enter multi-line insttructions against a given asset.
 * This is stored as asset.meta.notes and is toggled by
 * props.clientConfig.showNotes, and limited to length as given by
 * props.clientConfig.notesCharLimit
 */
const AssetNotes: FunctionComponent<AssetViewProps> = (props) => {
  // const handleChange = (event) => {
  //   props.asset.meta["notes"] = event.target.value;
  //   props.onModified(props.asset.meta);
  // }

  if (!props.clientConfig.showNotes) {
    return null;
  }

  const charLimit = props.clientConfig.notesCharLimit ? props.clientConfig.notesCharLimit : 2048;

  const text = props.asset.meta.notes ? props.asset.meta.notes : "";

  return (
    
    <div className="form-group">
      <label htmlFor={"notes-for-" + props.asset.file.name}>Notes</label>
      <textarea
        className="form-control"
        id={"notes-for-" + props.asset.file.name}
        rows={2}
        maxLength={charLimit}
        onChange={(e) => {
          props.asset.meta["notes"] = e.target.value.toString().substr(0, charLimit);
          props.onModified(props.asset.meta);
        }}
        value={props.asset.meta.notes}
        disabled={props.isUploading || props.asset.uploadProgress?.complete}
      ></textarea>
      <p className="text-right">
        <small>{charLimit - text.length + " characters remaining"}</small>
      </p>
      <MDBBtn
        onClick={(e) => props.applyAll("notes", props.asset.meta.notes)}
        color="default"
        className="apply-to-all w-100"
        size="sm"
        disabled={props.isUploading || props.asset.uploadProgress?.complete}
      >
        Apply to all
      </MDBBtn>
    </div>
  );
};

export default AssetNotes;
