import { MDBCard, MDBCardBody, MDBCol, MDBRow } from "mdbreact";
import React from "react";

const ClientInstructions = ({ invitation }) => {

  if(invitation.clientConfig.t1cCompanyName == null) {
    return null;
  }

  return (
    <MDBCard className="mb-4 client-instructions">
      <MDBCardBody>
        <MDBRow>
          <MDBCol size="4">
            <p>
              <strong>Company:</strong> {invitation.clientConfig.t1cCompanyName}
            </p>
          </MDBCol>
          <MDBCol size="4">
            <p>
              <strong>Project:</strong> {invitation.clientConfig.t1cProjectName}
            </p>
          </MDBCol>
          <MDBCol size="4">
            <p>
              <strong>Default turnaround:</strong> {invitation.clientConfig.t1cDefaultTurnaround}
            </p>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  );
};

export default ClientInstructions;
