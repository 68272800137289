import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle } from 'mdbreact';
import React, { FunctionComponent } from 'react';
import Layout from "../../layout/Layout";

const NotFound: FunctionComponent = () => {

  const body = (
    <div className="login-container text-center">
      <img src="/loginLogo.png" alt="Take 1" className="img-thumbnail rounded  mx-auto img-fluid login-logo" />
      <MDBCard style={{ width: "480px", marginLeft: '50%', transform: 'translateX(-50%)' }}>
        <MDBCardHeader color="default-color">
          <MDBCardTitle tag="h1" className="h6 mb-0 p-1">
            404: Uploader was refreshed
            </MDBCardTitle>
        </MDBCardHeader>
        <MDBCardBody>
          <p>You have received this error because the uploader was refreshed. Please close this tab and re-open the uploader from the original source to upload your files.</p>
        </MDBCardBody>
      </MDBCard>
    </div>
  )
  return <Layout body={body} />
}

export default NotFound;
