import React, { FunctionComponent, useState, Fragment, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import Layout from "../../../layout/Layout";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBInput } from "mdbreact";
import showNotification from "../../../helpers/showNotification";

import UploadInvitationModel from "../../UploadInvitationModel";
import UploadSessionModel from "../../UploadSessionModel";

interface PasscodePromptProps {
  uploadSession: UploadSessionModel;
  setUploadSession: (as: UploadSessionModel) => void;
}

/**
 * Presents an interface for capturing a passcode relating to a one-time
 * upload invitation link.
 * The token is provided as part of the URL context, the passcode is entered
 * manually and compared against a record set in the back-end.
 * If the invitation is recognised, the API will return temporary IAM credentials
 * and a callback is used to set the App's uploadSession.
 * The component should then redirect to "/upload"
 */
const PasscodePromptView: FunctionComponent<PasscodePromptProps> = (props) => {
  const [passcode, setPasscode] = useState<string>("");
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  // const [error, setError] = useState<string | null>(null);
  const token = useParams<string>();

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsWaiting(true);

    UploadInvitationModel.claim(token.token, passcode)
      .then((invitation: UploadInvitationModel) => {
        invitation.clientConfig.displayCaptureModal = true;
        // invitation = addHardCodedTags(invitation)
        props.setUploadSession({
          isAuthenticated: true,
          invitation: invitation,
          token: token.token,
        });
      })
      .catch((e) => {
        console.error(e);
        showNotification("Error", e, "warning");
        setIsWaiting(false);
        // setError(e);
      });
  };

  // const addHardCodedTags = invitation => {
  //   invitation.clientConfig.primary_asset_tags = [
  //     'B-roll',
  //     'Interview',
  //     'Reality',
  //     'Timelapse'
  //   ]
  //   invitation.clientConfig.supporting_asset_tags = [
  //     'Facesheet'
  //   ]
  //   return invitation
  // }

  const body = (
    <Fragment>
      {props.uploadSession.isAuthenticated ? (
        <Redirect to="/upload" />
      ) : (
          <div className="login-container text-center">
            <img src="/loginLogo.png" alt="Take 1" className="img-thumbnail rounded  mx-auto img-fluid login-logo" />
            <MDBCard style={{ width: "480px", marginLeft: "50%", transform: "translateX(-50%)" }}>
              <MDBCardHeader color="default-color">
                <MDBCardTitle tag="h1" className="h6 mb-0 p-1">
                  Take 1 uploader
              </MDBCardTitle>
              </MDBCardHeader>
              <MDBCardBody>
                <fieldset disabled={isWaiting}>
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <MDBInput
                      name="passcode"
                      getValue={(e) => setPasscode(e.toString())}
                      label="Please enter your passcode"
                    />
                    <MDBBtn type="submit" color="default" onClick={(e) => handleSubmit(e)}>
                      Submit
                  </MDBBtn>
                  </form>
                </fieldset>
              </MDBCardBody>
            </MDBCard>
          </div>
        )}
    </Fragment>
  );

  return <Layout body={body} />;
};

export default PasscodePromptView;
