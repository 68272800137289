import React, { FunctionComponent, useState, Fragment, useEffect } from 'react';
import { useParams, Redirect } from "react-router-dom";

import UploadInvitationModel from "../UploadInvitationModel";
import UploadSessionModel from "../UploadSessionModel";

interface T1ConsoleAuthBridgeProps {
  uploadSession: UploadSessionModel;
  setUploadSession: (as: UploadSessionModel) => void;
}

/**
 * Allows a T1 Console user to access an upload session from a one-time link
 */
const T1CConsoleAuthBridge: FunctionComponent<T1ConsoleAuthBridgeProps> = (props) => {

  const [isWorking, setIsWorking] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const {token, passcode} = useParams<string>();

  useEffect(() => {

    if (!isWorking) { //mutex
      UploadInvitationModel.claim(token, passcode).then(
        (invitation: UploadInvitationModel) => {
          setIsWorking(true);
          props.setUploadSession({
            isAuthenticated: true,
            invitation: invitation,
            token: token
          });
        }).catch(e => {
          console.error(e);
          setError(e);
        });
    }
  }, [error, isWorking, props, token, passcode])

  return (
    <Fragment>
      {props.uploadSession.isAuthenticated ? <Redirect to='/upload' /> : (
        error !== null ? <p>{error}</p> : <div className="uploader-transition"><div className="loader">Loading...</div> <p className="uploader-transition-text">Loading Uploader</p></div>
      )
      }
    </Fragment>
  )
}

export default T1CConsoleAuthBridge;
