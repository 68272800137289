import UploadInvitationModel from "./UploadInvitationModel";

/**
 * Describes the state of a client's upload session.
 **/
export default class UploadSessionModel {
  isAuthenticated : boolean;
  token: string;
  invitation: UploadInvitationModel|null;

  public constructor({isAuthenticated=false, token, invitation=null}) {
    this.isAuthenticated = isAuthenticated;
    this.token = token;
    this.invitation = invitation;
  }
}
