import React from "react";
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem } from "mdbreact";

const Header = ({ loggedIn = null }) => {
  let headerText;
  if (loggedIn) {
    if ("t1cProjectName" in loggedIn.clientConfig) {
      document.title = "Take 1 Uploader / " + loggedIn.clientConfig.t1cProjectName;
    }
    headerText = (
      <span className="white-text header-client-name">
        {loggedIn.clientConfig.t1cCompanyName}
        <br />
        {loggedIn.clientConfig.t1cUserFullName}
      </span>
    );
  }
  return (
    <MDBNavbar color="default-color" dark expand="md" fixed="top">
      <MDBNavbarBrand href="/">
        <img src={process.env.PUBLIC_URL + "/headerLogo.png"} alt="Take 1" height="51" />
      </MDBNavbarBrand>
      <MDBNavbarNav right>
        <MDBNavItem>
          <i data-test="fa" className="fa47 fa47-user-circle fa-3x mr-2 white-text"></i>
          {headerText}
          {/* <span className="white-text header-client-name">{loggedIn.clientConfig.t1cUserFullName}</span> */}
        </MDBNavItem>
      </MDBNavbarNav>
    </MDBNavbar>
  );
};

export default Header;
