import React, { FunctionComponent } from "react";
import { MDBIcon } from "mdbreact";

import { AssetViewProps } from "./AssetView";

/**
 * Presents a button that will enable removing an asset from the upload list.
 * This uses props.remove, a callback provided by the parent context.
 */
const AssetDeletionButton: FunctionComponent<AssetViewProps> = (props) => {
  let deleteIcon = <MDBIcon icon="" />;
  if (!props.isUploading && !props.asset.uploadProgress?.complete) {
    deleteIcon = (
      <MDBIcon
        icon="times"
        className="float-right asset-remove clickable"
        size="lg"
        onClick={(e) => {
          props.remove();
        }}
      />
    );
  }
  return deleteIcon;

  // return (<MDBIcon icon="times" className="float-right asset-remove clickable" size="lg" onClick={(e) => { props.remove() }} />
  // <Fragment>
  //   <div>
  //     <MDBBtn color="danger" onClick={(e)=>{props.remove()}}>Remove</MDBBtn>
  //     {/* <button onClick={(e)=>{props.remove()}}>Remove?</button> */}
  //   </div>
  // </Fragment>
  // )
};

export default AssetDeletionButton;
